<template>
  <b-row class="px-1">
    <!-- Deals section -->
    <b-row class="m-0 w-100 mb-2">
      <b-col :class="`${is_avaible_to_editing && !is_in_sidebar_chat ? 'col-12 col-md-8' : 'col-12'} p-0`">
        <div class="w-100 text-left">
          <h4 class="text-left">{{ $t('creator.dealsStep') }}</h4>
          <p class="avenir-medium">{{ $t('creator.chooseOne') }}</p>
        </div>
        <b-form-radio-group v-model="selected_deal_radio" class="w-100">
          <b-col 
            :class="`card-radion-button-steps col-12 ${index + 1 === deals.length ? '' : 'mb-1'}`" 
            v-for="(deal, index) in deals" 
            :key="index"
            ref="card_radio_button"
            @click="getActiveDeal(deal, index)"
          >
            <div class="background-left-proposal-radios"/>
            <div class="text-left text-gray-600">
              <div class="float-right d-flex">
                <span v-if="!deal.is_brandme_talent" class="mr-1 avenir-bold">$ {{separatebycomma(deal.price)}} USD</span>
                <span v-else class="mr-1 avenir-bold">
                  <span v-for="index in deal.range_price" :key="index">$</span>
                </span>
                <b-form-radio :value="deal"></b-form-radio>
              </div>
              <span class="w-100 mb-1 d-block avenir-bold">{{deal.title}}</span>
              <p class="d-flex flex-wrap">
                <span class="d-flex align-items-center mr-1">
                  <feather-icon icon="CalendarIcon" class="mr-05"/>
                  {{deal.days_delivery}} <span class="avenir-medium ml-02">{{ $t('creator.daysDelivery') }}</span>
                </span>
                <span class="d-flex align-items-center mr-1">
                  <feather-icon icon="RepeatIcon" class="mr-05"/>
                  {{deal.revision}} <span class="avenir-medium ml-02">{{ $t('creator.dealRevision') }}</span>
                </span>
                <span class="d-flex align-items-center mr-1">
                  <b-icon icon="shield-check" class="mr-05"/>
                  <span class="avenir-medium">
                    {{$t('creator.licensing').find((licence) => licence.value === deal.licensing).text}}
                  </span>
                </span>
              </p>
              
              <p class="avenir-medium" v-if="!loaded_ia">{{deal.description}}</p>
              <div v-else>
                <b-skeleton width="100%"></b-skeleton>
                <b-skeleton width="85%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
              </div>
              <hr class="mb-1"/>
              <span class="w-100 mb-1 d-block">{{ $t('creator.dealFeaures') }}</span>
              <b-badge v-for="(feature, index) in deal.features" :key="index" variant="btn-characteristics" class="btn-characteristics mr-1 mb-1">
                <feather-icon icon="CheckIcon"></feather-icon>
                {{ getTitle(feature) }}
              </b-badge>
            </div>
            <div class="background-button-edit" @click="$emit('edit_proposal', 'deals', deal)" v-if="is_avaible_to_editing && !is_in_sidebar_chat">
              <h5 class="m-0 text-white animation-opacity-words">{{ $t('creator.editDeal') }}</h5>
            </div>
          </b-col>
        </b-form-radio-group>

        <b-button v-if="(deals.length < 4 && is_avaible_to_editing) && !is_in_sidebar_chat" class="add-deal-action mt-2" variant="add-deal-action" @click="$emit('edit_proposal', 'deals')">
          <span class="avenir-bold">{{ $t('creator.addDeal') }}</span>
        </b-button>
      </b-col>
      
      <b-col class="d-none d-md-block col-4 p-0 pl-2 mt-5" v-if="is_avaible_to_editing && !is_in_sidebar_chat">
        <div class="bubble-edit" @click="deals.length < 4 ? $emit('edit_proposal', 'deals'): ''">
          <feather-icon icon="Edit2Icon"></feather-icon>
          <span class="ml-05 avenir-bold">{{ $t('creator.dealsStep') }}</span>
          <p class="avenir-medium m-0 mt-1">{{ $t('creator.editDealDesc') }}</p>
        </div>        
      </b-col>
    </b-row>
    <!-- ------------------- -->

    <!-- Options actions when options has a length > 0-->
    <b-row class="m-0 w-100 mb-2" v-if="options.length > 0">
      <b-col :class="`${is_avaible_to_editing && !is_in_sidebar_chat ? 'col-12 col-md-8' : 'col-12'} p-0`">
        <div v-if="options.length > 0" class="w-100">
          <div class="w-100 text-left">
            <h4 class="my-1 text-left">{{ $t('creator.optionsStep') }}</h4>
            <p class="avenir-medium">{{ $t('creator.chooseMore') }}</p>
          </div>
          <b-col 
            :class="`card-radion-button-steps col-12 ${index + 1 === options.length ? '' : 'mb-1'}`" 
            v-for="(option, index) in options" 
            :key="index" 
            @click="getActiveCheckbox(option, index)"
            ref="card_checkbox_button"
          >
            <div class="background-left-proposal-checkboxs"/>
            <div class="text-left text-gray-600">
              <div class="float-right d-flex position-relative">
                <span v-if="!option.is_brandme_talent" class="mr-1 avenir-bold">${{separatebycomma(option.price)}}</span>
                <span v-else class="mr-1 avenir-bold">
                  <span v-for="index in option.range_price" :key="index">$</span>
                </span>
                <b-form-checkbox :value="option" v-model="checked_option" @click="getActiveCheckbox(option, index)"></b-form-checkbox>
                <div class="hover-transparent"></div>
              </div>
              <span class="w-100 mb-1 d-block avenir-bold">{{option.title}}</span>
              <p class="d-flex">
                <span class="d-flex align-items-center mr-1">
                  <feather-icon icon="CalendarIcon" class="mr-05"/>
                  {{option.days_delivery}} <span class="avenir-medium ml-02">{{ $t('creator.daysDelivery') }}</span>
                </span>
              </p>
              <p class="m-0 avenir-medium">{{option.description}}</p>
            </div>
            <div class="background-button-edit" @click="$emit('edit_proposal', 'options', option)" v-if="is_avaible_to_editing && !is_in_sidebar_chat">
              <h5 class="m-0 text-white animation-opacity-words">{{ $t('creator.editOption') }}</h5>
            </div>
          </b-col>
        </div>

        <b-button class="add-deal-action mt-2" variant="add-deal-action" @click="$emit('edit_proposal', 'options')" v-if="(is_avaible_to_editing && options.length < 4) && !is_in_sidebar_chat">
          <span class="avenir-bold">{{ $t('creator.addOption') }}</span>
        </b-button>
      </b-col>

      <b-col class="d-none d-md-block col-4 p-0 pl-2 mt-6" v-if="is_avaible_to_editing && !is_in_sidebar_chat">
        <div class="bubble-edit" @click="options.length < 4 ? $emit('edit_proposal', 'options') : ''">
          <div>
            <feather-icon icon="Edit2Icon"></feather-icon>
            <span class="ml-05 avenir-bold">{{ $t('creator.optionsStep') }}</span>
            <p class="avenir-medium m-0 mt-1">{{ $t('creator.editOptionDesc') }}</p>
          </div>
        </div>        
      </b-col>
    </b-row>
    <!-- ------------------------------------------------ -->

    <!-- Options actions when options has a length 0 -->
    <b-row class="m-0 w-100 mb-2" v-if="is_avaible_to_editing && options.length === 0">
      <b-col class="col-12 col-md-8 p-0 m-0">
        <b-button  class="add-option-action" variant="add-option-action" @click="$emit('edit_proposal', 'options')">
          <h4 class="avenir-bold">{{ $t('creator.addOption') }}</h4>
          <p class="avenir-medium m-0">{{ $t('creator.addOptionDesc2') }}</p>
          <div class="box-add-option-absolute avenir-bold">
            {{ $t('creator.addOption') }}
          </div>
        </b-button>
      </b-col>
      <b-col class="d-none d-md-block col-4 p-0 pl-2">
        <div class="bubble-edit purple-edit-bubble" @click="$emit('edit_proposal', 'options')">
          <div>
            <b-icon icon="brightness-high"></b-icon>
            <span class="ml-05 avenir-bold">{{ $t('creator.sugOption') }}</span>
            <p class="avenir-medium m-0">{{ $t('creator.sugOptionDesc') }}</p>
          </div>
        </div>        
      </b-col>
    </b-row>
    <!-- ------------------------------------------ -->
    
    <!-- Request section when has a length > 0-->
    <b-row class="w-100 m-0 mb-2" v-if="requests.length > 0">
      <b-col :class="`${is_avaible_to_editing && !is_in_sidebar_chat? 'col-12 col-md-8' : 'col-12'} p-0`">
        <div class="w-100 text-left">
          <h4 class="my-1 text-left">{{ $t('creator.sellerRequest') }}</h4>
          <p class="avenir-medium">{{ $t('creator.completeListing') }}</p>

          <b-col :class="`card-radion-button-steps col-12 ${index + 1 === requests.length ? '' : 'mb-1'}`" v-for="(request, index) in requests" :key="index" v-b-toggle="`collapse_${index}`" @click="clickCollapse(index, request)">
            <div class="text-left text-gray-600">
              <div class="d-flex justify-content-between align-items-center">
                <b-icon :icon="getIconsRequest(request.type_question)" class="mr-05"></b-icon>
                <span class="w-100 d-block">{{index + 1}}. {{request.question}}</span>
                <div class="container-plus-less-icon" v-if="request.options.length > 0 && !is_avaible_to_editing" :ref="`icon_plus_${index}`">
                  <span></span>
                  <span></span>
                </div>
              </div>
              <b-collapse :id="`collapse_${index}`" class="pl-3" v-if="!is_avaible_to_editing">
                <div v-for="(option, index) in request.options" :key="index">
                  <span>{{String.fromCharCode(index + 65)}}. </span>
                  <span class="avenir-medium">{{option.title}}</span>
                </div>
              </b-collapse>
              <div v-else class="pl-3">
                <div v-for="(option, index) in request.options" :key="index">
                  <span>{{String.fromCharCode(index + 65)}}. </span>
                  <span class="avenir-medium">{{option.title}}</span>
                </div>
              </div>
            </div>

            <div class="background-button-edit" @click="$emit('edit_proposal', 'requests', request)" v-if="is_avaible_to_editing && !is_in_sidebar_chat">
              <h5 class="m-0 text-white animation-opacity-words">{{ $t('creator.editSellReq') }}</h5>
            </div>
          </b-col>
        </div>

        <b-button class="add-deal-action mt-2" variant="add-deal-action" @click="$emit('edit_proposal', 'requests')" v-if="is_avaible_to_editing && requests.length < 4 && !is_in_sidebar_chat">
          <span class="avenir-bold">{{ $t('creator.addSellReq') }}</span>
        </b-button>
      </b-col>

      <b-col class="d-none d-md-block col-4 p-0 pl-2 mt-6" v-if="is_avaible_to_editing && !is_in_sidebar_chat">
        <div class="bubble-edit" @click="requests.length < 4 ? $emit('edit_proposal', 'requests') : ''">
          <div>
            <feather-icon icon="Edit2Icon"></feather-icon>
            <span class="ml-05 avenir-bold">{{ $t('creator.sellerRequests') }}</span>
            <p class="avenir-medium m-0 mt-1">{{ $t('creator.editSellReqDesc') }}</p>
          </div>
        </div>        
      </b-col>
    </b-row>
    <!-- ----------------- -->

    <!-- Request section when has a length = 0 -->
    <b-row class="m-0 w-100 mb-2" v-if="is_avaible_to_editing && requests.length === 0">
      <b-col class="col-12 col-md-8 p-0 m-0">
        <b-button  class="add-option-action" variant="add-option-action" @click="$emit('edit_proposal', 'requests')">
          <h4 class="avenir-bold">{{ $t('creator.sellerRequest') }}</h4>
          <p class="avenir-medium m-0">{{ $t('creator.completeListing') }}</p>
          <div class="box-add-option-absolute avenir-bold">
            {{ $t('creator.addSellReq') }}
          </div>
        </b-button>
      </b-col>
      <b-col class="d-none d-md-block col-4 p-0 pl-2">
        <div class="bubble-edit purple-edit-bubble" @click="$emit('edit_proposal', 'requests')">
          <div>
            <b-icon icon="brightness-high"></b-icon>
            <span class="ml-05 avenir-bold">{{ $t('creator.sugSellReq') }}</span>
            <p class="avenir-medium m-0">{{ $t('creator.sugSellReqDesc') }}</p>
          </div>
        </div>        
      </b-col>
    </b-row>
    <!-- --------------------------------- -->
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BFormRadioGroup,
  BFormRadio,
  BFormCheckbox,
  BCollapse,
  VBToggle,
  BButton,
  BSkeleton,
} from 'bootstrap-vue';
import { separatebycomma } from '@/libs/utils/formats'

export default {
  name: 'dealsOptionsRequests',
  components: {
    BRow,
    BBadge,
    BCol,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckbox,
    BCollapse,
    BButton,
    BSkeleton,
  },
  directives: {
    'b-toggle': VBToggle
  },
  props: {
    deals: {
      type: Array,
    },
    options: {
      type: Array,
    },
    requests: {
      type: Array,
    },
    unchecked_option: {
      type: String,
    },
    is_avaible_to_editing: {
      type: Boolean,
      default: false,
    },
    loaded_ia: {
      type: Boolean,
      default: false,
    },
    is_in_sidebar_chat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_deal_radio: null,
      selected_deal: null,
      checked_option: [],
      separatebycomma,
    }
  },
  created() {
    setTimeout(() => {
      if (!this.is_avaible_to_editing) this.getActiveDeal(this.deals[0], 0);
    }, 200);
  },
  computed: {

  },
  methods: {
    getTitle(feature) {
      if (!feature) return ''
      const result = this.$t('creator.features').find((feature_translation) => feature_translation.value === feature.title)
      return result ? result.text : ''
    },
    clickCollapse(index, request) {
      if ((request.type_question === 'radio' || request.type_question === 'checkbox') && !this.is_avaible_to_editing) {
        const container_icon = this.$refs[`icon_plus_${index}`][0];
        container_icon.classList.toggle("transition-icon-plus")
        container_icon.classList.toggle("transition-form")
      }
    },
    getIconsRequest(type) {
      if (type === 'free_text') return 'textarea-t';
      if (type === 'radio') return 'ui-radios';
      if (type === 'checkbox') return 'ui-checks';
      if (type === 'media') return 'file-earmark-arrow-up';
    },
    getActiveDeal(deal, index) {
      if (!this.is_avaible_to_editing) {
        this.clearActive();
        this.$refs.card_radio_button[index].classList.add('active-class-button-radio');
        this.selected_deal = deal;
        this.selected_deal_radio = deal;
        this.$emit('deal_selected', deal);
      }
    },
    getActiveCheckbox(option, index) {
      if (this.checked_option.find(i => i === option) !== undefined) {
        this.checked_option = this.checked_option.filter((i) => i !== option)
      } else {
        this.checked_option.push(option);
      }
      this.$emit('checked_options', this.checked_option);
      this.$refs.card_checkbox_button[index].classList.toggle('active-class-button-checkbox')
    },
    clearActive() {
      for (let index = 0; index < this.deals.length; index++) {
        this.$refs.card_radio_button[index].classList.remove('active-class-button-radio')
      }
    },
  },
  watch: {
    unchecked_option(value) {
      if (value.length > 0) {
        let index = 0;
        this.options.map((opt, i) => {
          if (opt.uuid === value) {
            index = i;
          }
        });
        this.getActiveCheckbox(this.options[index], index);
        this.$emit('reset_unchecked_option');
      }
    },
  }
}
</script>

<style scoped>
.btn-characteristics {
  color: #3483fa;
  background-color: rgba(65,137,230,.15);
  padding: 8px 10px;
}
.container-plus-less-icon {
  background: transparent;
  width: 30px;
  height: 30px;
  border: 0;
  position: relative;
}
.container-plus-less-icon span {
  position: absolute;
  transition: 300ms;
  background: #999999;
}
.container-plus-less-icon span:first-child {
  top: 25%;
  bottom: 25%;
  width: 10%;
  left: 45%;
}
.container-plus-less-icon span:last-child {
  left: 25%;
  right: 25%;
  height: 10%;
  top: 45%;
}
.transition-icon-plus span {
  transform: rotate(90deg);
}
.transition-form span:last-child {
  left: 50%;
  right: 50%;
}
</style>
<style>
.card-radion-button-steps {
  border-radius: 1em !important;
  background-color: white;
  overflow: hidden !important;
  padding: 2em;
  border: 0.0625rem solid rgba(222,226,230,.7); 
  position: relative;
}
.card-radion-button-steps:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  cursor: pointer;
  transition: 500ms;
}
.background-left-proposal-radios, .background-left-proposal-checkboxs {
  background-color: #dfdddd;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0px;
}
.no-cursor-pointer {
  cursor: initial !important;
}
.mr-05 {
  margin-right: 0.5em;
}
.ml-02 {
  margin-left: 0.2em;
}
.text-gray-600 {
  color: #6c757d !important;
}
.light-secondary-v2 {
  background-color: #EFF3F6;
  color: #718096;
  font-weight: 400;
}
.active-class-button-radio {
   box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.active-class-button-radio > .background-left-proposal-radios {
  background-color: #7367f0 !important;
}
.active-class-button-checkbox {
   box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.active-class-button-checkbox > .background-left-proposal-checkboxs {
  background-color: #7367f0 !important;
}
.add-deal-action {
  width: 100%;
  color: #3b8DA8;
  background-color: #E6f2f6;
  border: 1px solid #3b8DA8;
  padding: 2em;
  border-radius: 1em;
}
.add-deal-action:hover {
  color: #ffff;
  transition: all 500ms;
  background-color: #3b8DA8;
}
.add-option-action {
  width: 100%;
  text-align: left;
  border: 2px dashed #777777aa;
  color: #777777aa;
  border-radius: 1em; 
  position: relative;
}
.add-option-action h4 {
  color: #777777aa;
}
.box-add-option-absolute {
  background-color: #902875;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.7em;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.add-option-action:hover {
  border: 2px solid #902875;
  transition: all 500ms;
}
.add-option-action:hover .box-add-option-absolute {
  transition: all 500ms;
  opacity: 1;
}
.add-option-action:not(:hover) .box-add-option-absolute,
.add-option-action:not(:hover),
.add-deal-action:not(:hover)  {
  transition: all 500ms;
}
.purple-edit-bubble {
  height: 100%;
  background-color: #EEDFEA !important;
  color: #902875 !important;
}
.purple-edit-bubble:before {
  border: 10px solid #EEDFEA !important;
  content: '' !important;
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
  position: absolute !important;
  transform: rotate(90deg) !important;
  left: -20px !important;
}
.purple-edit-bubble:hover {
  background-color: #eed9e8 !important;
}
</style>
<style scoped>
.mt-6 {
  margin-top: 6em;
}
.hover-transparent {
  background-color: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.background-button-edit {
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  border-radius: 0.55em;
  z-index: 10;
}
.animation-opacity-words {
  opacity: 0;
}
.card-radion-button-steps:hover .background-button-edit
{
  background-color: #3b8da888;
  transition: all 500ms;
  cursor: pointer;
  backdrop-filter: blur(10px);
}
.card-radion-button-steps:hover .animation-opacity-words
{
  opacity: 1;
  transition: all 500ms;
}
.card-radion-button-steps:not(:hover) .background-button-edit, 
.card-radion-button-steps:not(:hover) .animation-opacity-words
{
  transition: all 500ms;
}


.bubble-edit {
  text-align: left;
  /* position: relative; */
  /* height: auto; */
  /* width: 100%; */
  padding: 1em;
  color: #3b8DA8;
  background: #E6f2f6;
  border-radius: 1em;
  cursor: pointer;
  position: sticky  ;
  position: -webkit-sticky;
  top: 12em;
}
.bubble-edit:hover {
  transition: all 300ms;
  background: #3b8da82f;
}
.bubble-edit:before {
  border: 10px solid #E6f2f6;
  content: '';
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  position: absolute;
  transform: rotate(90deg);
  left: -20px;
}
.bubble-edit:hover:before {
  border: 10px solid #3b8da82f;
  content: '';
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  position: absolute;
  transform: rotate(90deg);
  left: -20px;
  transition: all 300ms;
}
.ml-05 {
  margin-left: 0.55em;
}
/* colors */
/* 
color: #b78404;
background: #f8e7bd;

hover
border: 10px solid #f8e7bd;

*/
</style>